<a class="aui-text__body-s aui-link__secondary actions__item _disabled">
  <app-svg src="auiSvgBlog"></app-svg>
  <span><strong>Блог</strong></span>
  <tui-svg src="tuiIconArrowUpRight"></tui-svg>
</a>
<hr class="actions__separator" />
<a [tuiDropdown]="contentGameKeys"
  class="aui-text__body-s aui-link__secondary actions__item"
  [tuiDropdownAlign]="'center'"
  tuiDropdownHover
>
  <app-svg [src]="items[0]?.icon"></app-svg>
  <span><strong>{{ items[0]?.name }}</strong></span>
  <tui-svg src="tuiIconChevronDown"></tui-svg>
  <ng-template #contentGameKeys>
    <div class="aui-dropdown dropdown__container">
      <a *ngFor="let item of items[0]?.actions; index as i"
        class="aui-text__body-l aui-dropdown__item"
        [routerLink]="item?.link"
        [class.aui-link__primary]="i === 0"
        [class.aui-link__secondary]="i !== 0"
        [class._disabled]="!item?.link"
      >
        {{ item?.name }}
      </a>
    </div>
  </ng-template>
</a>
<a [tuiDropdown]="contentPaymentCards"
  class="aui-text__body-s aui-link__secondary actions__item"
  [tuiDropdownAlign]="'center'"
  tuiDropdownHover
>
  <app-svg [src]="items[1]?.icon"></app-svg>
  <span><strong>{{ items[1]?.name }}</strong></span>
  <tui-svg src="tuiIconChevronDown"></tui-svg>
  <ng-template #contentPaymentCards>
    <div class="aui-dropdown dropdown__container">
      <a *ngFor="let item of items[1]?.actions; index as i"
        class="aui-text__body-l aui-dropdown__item"
        [routerLink]="item?.link"
        [class.aui-link__primary]="i === 0"
        [class.aui-link__secondary]="i !== 0"
        [class._disabled]="!item?.link"
      >
        {{ item?.name }}
      </a>
    </div>
  </ng-template>
</a>
<hr class="actions__separator" />
<a class="aui-text__body-m aui-link__secondary actions__item" routerLink="/cart">
  <tui-svg src="tuiIconShoppingCart"></tui-svg>
  <span class="mobile-span">Корзина</span>
  <tui-svg src="tuiIconArrowUpRight"
    class="mobile-svg"
  ></tui-svg>
</a>
<a class="aui-text__body-m aui-link__secondary actions__item" routerLink="/auth"
  *ngIf="!sessionID"
>  
  <tui-svg src="tuiIconLogIn"></tui-svg>
  <span class="mobile-span">Войти</span>
  <tui-svg src="tuiIconArrowUpRight"
    class="mobile-svg"
  ></tui-svg>
</a>
<a [tuiDropdown]="contentUser"
  class="aui-text__body-m aui-link__secondary actions__item"
  *ngIf="sessionID"
  [tuiDropdownAlign]="'center'"
  tuiDropdownHover
>
  <tui-svg src="tuiIconUser"></tui-svg>
  <span class="mobile-span">Профиль</span>
  <tui-svg src="tuiIconChevronUp"
    class="mobile-svg"
  ></tui-svg>
  <ng-template #contentUser>
    <div class="aui-dropdown dropdown__container">
      <a class="aui-text__body-l aui-dropdown__item aui-link__primary"
        [routerLink]="['/profiles', sessionID]"
      >
        <tui-svg src="tuiIconUser"></tui-svg> Личный кабинет
      </a>
      <a class="aui-text__body-l aui-dropdown__item aui-link__secondary _disabled">
        <tui-svg src="tuiIconBell"></tui-svg> Уведомления
      </a>
      <a class="aui-text__body-l aui-dropdown__item aui-link__secondary"
        [routerLink]="['/profiles', 'settings']"
      >
        <tui-svg src="tuiIconSettings"></tui-svg> Настройки
      </a>
      <a class="aui-text__body-l aui-dropdown__item aui-link__secondary"
        [routerLink]="['/profiles', 'security']"
      >
        <tui-svg src="tuiIconShield"></tui-svg> Безопасность
      </a>
      <a class="aui-text__body-l aui-dropdown__item aui-link__secondary _exit"
        (click)="exit()"
      >
        <tui-svg src="tuiIconLogOut"></tui-svg> Выйти
      </a>
    </div>
  </ng-template>
</a>